<template v-if="fromPage&&events">
    <ul class="event" v-if="!showFullMonth">
        <li><h2>{{dateString}}</h2></li>
        <li v-for="event in events" :key="event">
            <div><b>{{ event.Title}}</b></div>
            <div><span :style="{color: event.LocationColor}">•</span>
                    {{ event.LocationName}}
                <span v-if="event.datesEqual">mellan kl {{ event.FromTime }} och {{ event.ToTime }}</span>
                <span v-else>mellan {{formatFromDateString(event)}} och {{formatToDateString(event)}}</span>
            </div>
            <div v-html="event.Introduction"></div>
            <router-link v-if="event.PageUrl" :to="'/' + event.PageUrl" @click="$emit('openevent')">Läs mer</router-link>
            <router-link v-else-if="event.ContentLength>10" :to="'/kalender/' + event.Url + '-' + event.Id" @click="$emit('openevent')">Läs mer</router-link>
        </li>
        <li v-if="events.length===0">
            Inga händelser
        </li>
    </ul>
    <Calendar v-if="fromPage" :from-page="fromPage" is24hr is-expanded color="green" :attributes='attributes' @update:fromPage="updateCalendar" @dayclick="dayClick" />
    <table v-if="fromPage&&events && showFullMonth">
          <tr>
            <th>Vecka</th>
            <th>Dag</th>
            <th>Aktiviteter</th>
          </tr>
          <tr v-for="day in daysInMonth" :key="day">
            <td class="week" v-if="day.firstDayOfWeek" :rowspan="daysInWeek(day.week)">{{day.week}}</td>
            <td class="day">{{formatDayString(day.date)}}</td>
            <td>
              <ul>
                <li v-for="event in day.events" :key="event">
                  <div><b><span :style="{color: event.LocationColor}">•</span>{{ event.Title}}</b></div>
                    <div>
                      {{ event.LocationName}}
                      <span v-if="event.datesEqual">mellan kl {{ event.FromTime }} och {{ event.ToTime }}</span>
                      <span v-else>mellan {{formatFromDateString(event)}} och {{formatToDateString(event)}}</span>
                    </div>
                    <div v-html="event.Introduction"></div>
                    <router-link v-if="event.PageUrl" :to="'/' + event.PageUrl">Läs mer</router-link>
                    <router-link v-else-if="event.ContentLength>10" :to="'/kalender/' + event.Url + '-' + event.Id">Läs mer</router-link>
                </li>
              </ul>
            </td>
          </tr>
        </table>
</template>
<script>
import 'v-calendar/dist/style.css'
import { Calendar } from 'v-calendar'
export default {
  components: {
    Calendar
  },
  data () {
    return {
      eventsToUse: null
    }
  },
  props: {
    showFullMonth: {
      default: false,
      type: Boolean
    },
    date: {
      default: null,
      type: Date
    }
  },
  computed: {
    fromPage () {
      if (!this.date) return null
      return { month: this.date.getMonth() + 1, year: this.date.getFullYear() }
    },
    attributes () {
      const data = this.eventsToUse || this.$store.state.events
      if (!data) {
        return null
      }
      const eventList = [{
        key: 'today',
        highlight: {
          fillMode: 'light',
          color: 'green'
        },
        dates: new Date()
      }]
      data.forEach(event => {
        let fromDate = new Date(event.FromDate)
        let toDate = new Date(event.ToDate)
        fromDate = new Date(fromDate.toISOString().slice(0, -1))
        toDate = new Date(toDate.toISOString().slice(0, -1))

        const newEvent = {
          dates: { start: fromDate, end: toDate },
          customData: event
        }

        if (fromDate.getFullYear() === toDate.getFullYear() &&
                fromDate.getMonth() === toDate.getMonth() &&
                fromDate.getDate() === toDate.getDate()) {
          newEvent.dot = {
            color: event.LocationColor,
            class: toDate < new Date() ? 'past' : ''
          }
          event.datesEqual = true
        } else {
          newEvent.highlight = {
            start: { fillMode: 'solid', color: event.LocationColor },
            base: { fillMode: 'light', color: event.LocationColor },
            end: { fillMode: 'solid', color: event.LocationColor }
          }
          event.datesEqual = false
        }
        eventList.push(newEvent)
      })
      return eventList
    },
    events () {
      const now = this.date
      now.setHours(0, 0, 0)
      const data = this.eventsToUse || this.$store.state.events
      if (!data) { return [] }
      if (this.showFullMonth) { return data }
      const events = data.filter(i => {
        let fromDate = new Date(i.FromDate)
        fromDate = new Date(fromDate.toISOString().slice(0, -1))
        fromDate.setHours(0, 0, 0)
        let toDate = new Date(i.ToDate)
        toDate = new Date(toDate.toISOString().slice(0, -1))
        toDate.setHours(23, 59, 59)
        return now >= fromDate && now <= toDate
      })
      return events
    },
    dateString () {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return this.date.toLocaleDateString('sv-SE', options)
    },
    daysInMonth () {
      var date = new Date(this.date)
      var month = date.getMonth()
      let week = 0
      date.setDate(1)
      var allDays = []
      while (date.getMonth() === month) {
        const monthDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
        const newWeek = this.getWeek(monthDate)
        const data = this.eventsToUse || this.$store.state.events
        const events = data.filter(i => {
          let fromDate = new Date(i.FromDate)
          fromDate = new Date(fromDate.toISOString().slice(0, -1))
          fromDate.setHours(0, 0, 0)
          let toDate = new Date(i.ToDate)
          toDate = new Date(toDate.toISOString().slice(0, -1))
          toDate.setHours(23, 59, 59)
          return monthDate >= fromDate && monthDate <= toDate
        })
        allDays.push({ date: monthDate, week: newWeek, firstDayOfWeek: week !== newWeek, events: events })
        week = newWeek
        date.setDate(date.getDate() + 1)
      }
      return allDays
    }
  },
  methods: {
    daysInWeek (week) {
      return this.daysInMonth.filter(i => i.week === week).length
    },
    formatFromDateString (event) {
      const options = { month: 'long', day: 'numeric' }
      return new Date(event.FromDate).toLocaleDateString('sv-SE', options) + ' kl ' + event.FromTime
    },
    formatToDateString (event) {
      const options = { month: 'long', day: 'numeric' }
      return new Date(event.ToDate).toLocaleDateString('sv-SE', options) + ' kl ' + event.ToTime
    },
    formatDayString (date) {
      const options = { weekday: 'short', day: 'numeric' }
      return new Date(date).toLocaleDateString('sv-SE', options)
    },
    dayClick (obj) {
      this.$emit('day', obj.date)
    },
    getWeek (dateToUse) {
      const date = new Date(dateToUse)
      date.setHours(0, 0, 0, 0)
      // Thursday in current week decides the year.
      date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)
      // January 4 is always in week 1.
      const week1 = new Date(date.getFullYear(), 0, 4)
      // Adjust to Thursday in week 1 and count number of weeks from date to week1.
      const week = 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 -
                        3 + (week1.getDay() + 6) % 7) / 7)
      return week
    },
    updateCalendar (obj) {
      fetch('/api/calendar?date=' + obj.year + '-' + obj.month.toString().padStart(2, '0'))
        .then(response => response.json())
        .then(data => {
          this.eventsToUse = data
          this.$emit('month', new Date(obj.year, obj.month - 1, 1))
        })
    }
  }
}
</script>
<style lang="scss" scoped>
table {
  width:100%;
  border-collapse: collapse;
  padding:1rem;
  li {
    margin-bottom:0.5rem;
  }
}
th {
  border: 1px solid lightgray;
  background-color: #F8F8F7;
}
td {
  &.week {
    width: 2rem;
    text-align: center;
  }
  &.day {
    width:4rem;
    text-align: center;
  }
  padding:0.3rem;
  border: 1px solid lightgray;
  vertical-align: top;
}
.event {
    background-color: white;
    color: #000;
    border-radius: 0.5rem;
    margin-bottom:1rem;
    li {
        margin-bottom: 1rem;
        h2:first-letter {
            text-transform: uppercase;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    @media (max-width: 1022px) {
      margin-top:1rem;
      margin-bottom:1rem;
      padding: 1rem;
    }
}
.vc-day-popover-row {
    margin-top:5px;
    &-content {
        display:block;
    }
}

.vc-dot.past {
    opacity:0.6;
}
</style>
