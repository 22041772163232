<template>
  <div v-if="initialNews">
    <article v-for="post in initialNews" :key="post">
      <h2>{{ post.Title }}</h2>
      <small>Publicerat {{ post.CreatedDate }} av {{ post.DisplayName }}</small>
      <div v-html="post.Introduction"></div>
      <router-link
        v-if="post.ContentLength > 0"
        :to="'/nyheter/' + post.Url + '-' + post.Id"
        >Läs hela nyheten</router-link
      >
    </article>
    <article v-for="post in moreNews" :key="post">
      <h2>{{ post.Title }}</h2>
      <small>Publicerat {{ post.CreatedDate }} av {{ post.DisplayName }}</small>
      <div v-html="post.Introduction"></div>
      <router-link
        v-if="post.ContentLength > 0"
        :to="'/nyheter/' + post.Url + '-' + post.Id"
        >Läs hela nyheten</router-link
      >
    </article>
    <button v-if="initialNews.length+moreNews.length < totalPostCount" @click="loadMoreNews()">
      Visa fler nyheter
    </button>
  </div>
</template>
<script>
export default {
  props: {
    initialNews: [],
    totalPostCount: {}
  },
  data () {
    return {
      moreNews: []
    }
  },
  methods: {
    loadMoreNews () {
      let url = '/api/'
      if (this.$route.query.sokord) {
        url += 'search?sokord=' + this.$route.query.sokord + '&postOffset='
      } else {
        url += 'post?offset='
      }
      let totalCount = 10
      if (this.moreNews) {
        totalCount = this.initialNews.length + this.moreNews.length
      }
      url += totalCount
      fetch(url)
        .then(response => response.json())
        .then(data => { this.moreNews.push(...data.posts) })
    }
  }
}
</script>
<style lang="scss" scoped>
  article {
    margin-bottom: 3rem;
  }
</style>
