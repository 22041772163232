<template>
<header>
    <button @click="showMobileMenu()" aria-label="Visa/dölj navigering">Meny</button>
    <router-link to="/" class="home" @click="hideMobileMenu()"><button aria-label="Startsida">Start</button></router-link>
    <div>
        <button @click="toggleCalendar()" class="calendar" aria-label="Kalender">Kalender</button>
        <button class="search" @click="toggleSearch(this)" aria-label="Sök">Sök</button>
    </div>
    <div class="searchform" :class="{'searchform--active':showSearch}">
        <input type="text" v-model="searchWord" v-on:keyup.enter="search()" placeholder="Sök..."/>
    </div>
    <nav :class="{show:mobileMenuActive}">
        <router-link to="/"><img src="@/assets/logo.png" alt="Logo" height="70" width="64"></router-link>
        <ul v-if="$store.state.menu" class="menu">
            <li v-for="child in $store.state.menu[0].children" :key="child" @mouseover="showMenu(child.id)" @mouseleave="hideMenu()">
                <div v-if="child.children" @click="showMobileMenu(child.id)">
                    <router-link :to="'/' + child.Url" @click="hideMobileMenu()">{{child.label}}</router-link>
                    <div v-if="child.children && !activeSubMenues[child.id]">+</div>
                    <div v-else-if="child.children">-</div>
                </div>
                <div v-else  @click="goto(child.Url)">
                    <router-link :to="'/' + child.Url" @click="hideMobileMenu()">{{child.label}}</router-link>
                </div>
                 <ul v-if="child.children" class="submenu" :class="{'submenu--active': activeMenu==child.id, show: activeSubMenues[child.id]}">
                    <li v-for="subChild in child.children" :key="subChild" @mouseover="showMenu(child.id)" @click="goto(subChild.Url)">
                        &nbsp;&nbsp;&nbsp;<router-link :to="'/' + subChild.Url">{{subChild.label}}</router-link>
                    </li>
                </ul>
            </li>
        </ul>
        <input type="text" placeholder="Sök..." v-model="searchWord" v-on:keyup.enter="search()" />
    </nav>
    <div v-if="$store.state.events && showCalendar" class="popup-calendar">
        <Calendar :date="date" :showFullMonth="false" @day="setDay" @openevent="toggleCalendar()" />
        <div class="popup-calendar__full">
            <router-link to="/kalender" @click="toggleCalendar()">Se hela månaden</router-link>
        </div>
    </div>
</header>
</template>
<script>
import Calendar from '../subcomponents/Calendar.vue'
export default {
  components: {
    Calendar
  },
  data () {
    return {
      activeMenu: null,
      activeMobileMenu: false,
      activeSubMenues: [],
      stopHide: null,
      showSearch: false,
      mobileMenuActive: false,
      showCalendar: false,
      date: new Date(),
      searchWord: ''
    }
  },
  methods: {
    search () {
      this.$router.push({ path: '/sokresultat', query: { sokord: this.searchWord } })
      this.searchWord = ''
      this.showSearch = false
    },
    setDay (date) {
      this.date = date
    },
    toggleCalendar () {
      this.mobileMenuActive = false
      this.activeSubMenues = []
      this.showCalendar = !this.showCalendar
    },
    goto (url) {
      this.mobileMenuActive = false
      this.activeMenu = null
      this.activeSubMenues = []
      this.$router.push({ path: '/' + url })
    },
    showMobileMenu (childId) {
      this.showCalendar = false
      if (childId) {
        if (this.mobileMenuActive) {
          this.activeSubMenues[childId] = !this.activeSubMenues[childId]
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }
    },
    hideMobileMenu () {
      this.showCalendar = false
      this.mobileMenuActive = false
      this.activeSubMenues = []
    },
    showMenu (id) {
      this.activeMenu = id
      clearTimeout(this.stopHide)
    },
    hideMenu () {
      this.stopHide = setTimeout(() => {
        this.activeMenu = null
      }, 1000)
    },
    toggleSearch (el) {
      this.showSearch = !this.showSearch
      this.$nextTick(() => {
        document.getElementById('searchBox').focus()
      })
    }
  }
}
</script>
