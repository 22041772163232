<template>
  <footer v-if="$store.state.footer">
    <template v-if="$route.name!='Sponsors' && $store.state.footer.sponsors && $store.state.footer.sponsors.length > 0">
      <h2>Sponsorer</h2>
      <agile :options="sponsorOptions">
        <div v-for="sponsor in $store.state.footer.sponsors" :key="sponsor"  style="padding-left:3rem;padding-right:3rem;">
          <a v-if="sponsor.Url" :href="sponsor.Url" :title="sponsor.Title" rel="noopener" target="_blank">
            <img :alt="sponsor.Title" :src="'/sponsors/' + sponsor.ImageUrl" width="200" height="100" />
          </a>
          <img v-else :alt="sponsor.Title" :src="'/sponsors/' + sponsor.ImageUrl" width="200" height="100" />
        </div>
      </agile>
    </template>
    <nav>
      <section>
        <div v-html="$store.state.footer.Text1"></div>
      </section>
      <section>
        <div v-html="$store.state.footer.Text2"></div>
      </section>
      <section>
        <div v-html="$store.state.footer.Text3"></div>
      </section>
    </nav>
  </footer>
</template>
<script>
import { VueAgile } from 'vue-agile'
export default {
  components: {
    agile: VueAgile
  },
  data () {
    return {
      sponsorOptions: {
        autoplaySpeed: 5000,
        autoplay: true,
        dots: false,
        navButtons: false,
        slidesToShow: 8,
        responsive: [
          {
            breakpoint: 0,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1022,
            settings: {
              slidesToShow: 8
            }
          }
        ]
      }
    }
  }
}
</script>
