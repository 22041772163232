<style lang="scss">
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: flex;
  flex-direction: column;
}
a {
  color: #000;
}

h1,
h2 {
  color: #025f28;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}
ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}
main {
  flex: 1 0 auto;
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
input {
  display: block;
  min-width:250px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:focus-visible {
      outline:none;
  }
}
.breadcrumbs {
  li {
    list-style: none;
    display: inline-block;
    &:not(:last-of-type):after {
      content: "/";
      margin: 0 10px;
    }
  }
  margin: 0 auto;
  padding-top: 1rem;
  padding-left: 1rem;
}
header {
    position: sticky;
    top:0;
    align-items: center;
    background-color: #025F28;
    box-shadow: 0 4px 4px rgb(67 67 67 / 40%);
    z-index: 2;
    @media (max-width: 1022px) {
        display:flex;
        box-shadow: 0 4px 4px rgb(67 67 67 / 40%);
        justify-content: space-between;
        img {
            display:none;
        }
    }
    button {
        margin:10px;
        cursor: pointer;
        border: none;
        width: 30px;
        height: 30px;
        font-size: 0;
        &:first-of-type {
           background: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")
        }
        &.search {
            display:inline-block;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsEAAA7BAbiRa+0AAAKmSURBVEhLvZc7axVBGIZ3D0cx3k0RJZ2CgkELrSIEIjamEcXOShHRwp+UxjQiSmxDmvyDQCotArEQJCaYeEPiBcfnnctmcnZnd8/R4wNv5pLNvHP55huSZwmMMSMUF9A5NI4OIvELvUdv0Eqe5x/U2S9lY2O6JsumqF1B+21fPa/RIhP45Jrt2GPMKo9R3EEnbUd7vqN5zFdds5nC2JveR0dtxy47aA1tIjYjO4JOo1EUo9+9wFw70Igzdtv7gFq8Uhku8cFylue/XdcuTFTmM2jMdjh0/rOYb7hmGmvMIFcpplX3bKGnjYFjzD4mfIvahOuwvOPvZn09ScdHrwIpoJU2m4o8/8nM56m9dR2WccaMJ1JJB+nKxNG71Mo04I7hJYqP45Ivk8hY9zSwY8+0T5joR4pXrmU5g7quWo2MlRwCa1WB1JL4KukIa6+kjENGEroyg7Lty8ABX1Yi4xjdxUHp3anGrdbdCyg5DMohXwZ0O5LIWAk/oKQwKAqoAoK09thkrFcmMOozUn+QSPh50TUsGwTpN1+vRMYrrlow4wdqDYFxjSIO0t4xS3R8sogT+5hNg8b0Bl4l7NBliknXsmiljbkgDL6I9LQFJjC/x6DHfbuM8rQx16ndcB0FCyzmh68nsY+EYJCzFHqLiz7QFVFGUnLQPVVb0atA0pnG2ys+o2foC+ZfbU+C2ETm5yluo9o7mECm4S3X7j3BfN01y+wxFpjrfb2J4lRah850ASlWHqrDo3s8lzIvGQeYgJ42vTLa1qpA02Ov6F3WmfL9YeqPUZwqk+ZJ44gugyrha0AdgV6wzap7ynenKO6iRvM2xn3R1vyfG4s25kMxFk3mQzMWteauPjwS5uut8vHf4M90DmmlQv/qPHfV/4BWjh6hE1mWZX8Ay67bteznRoEAAAAASUVORK5CYII=)
        }
        &.calendar {
            background: url('~@/assets/calendar.png');
            display:inline-block;
            opacity: 0.5;
        }
    }
    img {
        margin: 10px 15px;
    }
    > *:nth-child(-n+3) {
        display:none;
        @media (max-width:1022px) {
            display:block;
        }
    }
    a {
        color: #FFF;
    }
    > a {
        text-decoration: none;
        font-size: 1.4rem;
        button {
            display:none;
        }
        &:before {
            content: "Ängelholms Ryttarförening";
            @media (max-width: 420px) {
                content: "Ängelholms Rf";
            }
        }
        @media (max-width:1022px) {
            display:inline-block;
        }
    }
    .searchform {
        display:none;
        &--active {
            display:block;
            width:100%;
            position: absolute;
            top:50px;
            background-color: #025F28;
            padding-top:0.7rem;
            padding-bottom:0.7rem;
        }
        input {
            width:90%;
            margin: 0 auto;
        }
    }
    nav {
        display:flex;
        align-items: center;
        justify-content: space-evenly;
        @media (max-width:1022px) {
            position:absolute;
            top:50px;
            width:100%;
            box-shadow: 0 4px 4px rgb(67 67 67 / 40%);
            max-height: 0;
            overflow: hidden;
            background-color: #fff;
            input, button {
                display:none;
            }
        }
        &.show {
            max-height:100vh;
            transition:max-height .25s ease-in;
        }
        a {
            text-decoration: none;
            font-size:1.2rem;
            &:hover {
                text-decoration: underline;
            }
             @media (max-width: 1022px) {
                 color:#025F28;
             }
        }
        > ul {
            li {
                cursor: pointer;
                display: list-item;
                @media (min-width: 1023px) {
                    display: inline-block;
                    padding-right: 1rem;
                    > div div {
                        display:none;
                    }
                }
                .show {
                    display:block;
                }
            }
            @media (max-width: 1022px) {
                width:100%;
                > li {
                    border-bottom: 1px solid lightgray;
                    > div {
                        padding:10px;
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #025F28;
                            color:#fff;
                            font-size:1.5rem;
                            border:1px solid lightgray;
                            width:1.5rem;
                            height: 1.5rem;
                            cursor: pointer;
                        }
                    }
                }
            }
            .submenu {
                display: none;
                @media (max-width: 1022px) {
                    background: rgba(162, 162, 162, 0.2) !important;
                    li {
                        border-top:1px solid lightgray;
                        padding-top:10px;
                        padding-bottom:10px;
                        padding-left:10px;
                    }
                }
                @media (min-width: 1023px) {
                    li {
                        display:list-item;
                    }
                    a {
                        color:#025F28;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    background-color: #F8F8F7;
                    position: absolute;
                    margin-top: 10px;
                    padding:1rem;
                    &--active {
                        &:before {
                            position: absolute;
                            top: -7px;
                            left: 9px;
                            display: inline-block;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid #ccc;
                            border-left: 7px solid transparent;
                            border-bottom-color: rgba(0, 0, 0, 0.2);
                            content: '';
                        }
                        &:after {
                            position: absolute;
                            top: -6px;
                            left: 10px;
                            display: inline-block;
                            border-right: 6px solid transparent;
                            border-bottom: 6px solid #ffffff;
                            border-left: 6px solid transparent;
                            content: '';
                        }
                        display: block;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}
footer {
  background-color: #025f28;
  .agile__list, h2 {
    background-color: #fff;
    text-align: center;
    margin: 0;
  }
  nav {
    max-width: 1500px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding:1rem;
    color: #fff;
    @media (max-width: 1022px) {
      flex-direction: column;
    }
    img {
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
.popup-calendar {
    color: #fff;
    padding-left:1rem;
    padding-right:1rem;
    padding-bottom:1rem;
    max-height:100vh;
    position:absolute;
    top:50px;
    left: 0;
    right: 0;
    background-color: #025F28;
    display:block;
    &__full {
        text-align:center;
        padding-top:1rem;
    }
}
article {
    ul li {
        list-style: initial;
        margin-left: 20px;
    }
}
</style>
<template>
  <header-item />
  <router-view />
  <footer-item />
</template>
