<template>
  <Head>
    <title>Startsida - Ängelholms Ryttarförening</title>
    <meta name="description" content="Ängelholms ryttarförening" />
  </Head>
  <agile v-if="start && start.slides" :autoplay="true" :autoplaySpeed="10000">
    <div v-for="slide in start.slides" :key="slide">
      <picture>
        <source media="(max-width: 640px)" :srcset="'/slides/' + slide.ImageSmallUrl" :alt="slide.Title"  width="640" height="167"/>
        <source media="(min-width: 641px)" :srcset="'/slides/' + slide.ImageUrl" :alt="slide.Title"  width="1920" height="500"/>
        <img class="agile__slide--image" :src="'/slides/' + slide.ImageUrl" :alt="slide.Title" width="1920" height="500" />
      </picture>
      <div class="agile__slide--overlay" v-if="slide.Header"></div>
      <div class="agile__slide--text" v-if="slide.Header">
        <div class="slide-text__title">{{ slide.Header }}</div>
        <div class="slide-text__text" v-if="slide.Usp1">{{ slide.Usp1 }}</div>
        <div class="slide-text__text" v-if="slide.Usp2">{{ slide.Usp2 }}</div>
      </div>
    </div>
  </agile>
  <main v-if="start">
    <PostsList
      :initialNews="start.posts"
      :totalPostCount="start.totalPostCount"
    />
    <aside>
      <Calendar :date="date" :showFullMonth="false" @day="setDay" />
    </aside>
  </main>
</template>
<script>
import Calendar from './subcomponents/Calendar.vue'
import PostsList from './subcomponents/PostsList.vue'
import { VueAgile } from 'vue-agile'
export default {
  components: {
    Calendar,
    PostsList,
    agile: VueAgile
  },
  beforeRouteEnter (to, _from, next) {
    next((vm) => vm.getPage())
  },
  beforeRouteUpdate (to, _from, next) {
    this.getPage()
    next()
  },
  data () {
    return {
      start: null,
      date: new Date()
    }
  },
  methods: {
    setDay (date) {
      this.date = date
    },
    getPage () {
      fetch('/api/start')
        .then((response) => response.json())
        .then((data) => {
          this.start = data
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  main {
    display: flex;
    justify-content: space-between;
  }
</style>
<style lang="scss">

aside {
  padding: 1rem;
  max-width: 300px;
  img {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1022px) {
  main {
    > .slideshow {
      display: none;
    }
  }
  aside {
    display: none !important;
  }
}
.post {
  margin-bottom: 3rem;
}

.agile {
  &__nav-button {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    height: 100%;
    position: absolute;
    top: -5px;
    transition-duration: 0.3s;
    width: 80px;

    &:hover {
      background-color: rgba(#000, 0.5);
      opacity: 1;
    }
    &--prev {
      left: 0;
    }
    &--next {
      right: 0;
    }
  }
  &__dots {
    bottom: 10px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  &__dot {
    margin: 0 10px;
    button {
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 10px;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      transition-duration: 0.3s;
      width: 10px;
    }
    &--current,
    &:hover {
      button {
        background-color: #fff;
      }
    }
  }
  &__slide {
    &--image {
      width: 100%;
      height: auto;
    }
    &--overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 1;
      margin-top: -5px;
      background-image: linear-gradient(
        110deg,
        rgba(0, 0, 0, 0.65) 50%,
        transparent 0
      );
      @media (max-width: 1022px) {
        background-image: linear-gradient(3deg, #025f28 48%, transparent 50%);
        position: relative;
        height: 30px;
        margin-top: -35px;
      }
    }
    &--text {
      @media (max-width: 1022px) {
        position: relative;
        background-color: #025f28;
        padding: 0rem 5px 25px 1rem;
        width: initial;
      }
      position: absolute;
      top: 0;
      text-align: left;
      margin: auto;
      padding: 2.5rem 1rem 0 2.5rem;
      width: 100%;
      max-width: 84rem;
      max-height: 100%;
      pointer-events: none;
      z-index: 2;
      color: #fff;
      .slide-text {
        &__title {
          width: 80%;
          font-size: 2.5vw;
          @media (max-width: 1022px) {
            font-size: 2rem;
            width: 100%;
          }
          @media (max-width: 800px) {
            font-size: 1.5rem;
            width: 100%;
          }
          @media (max-width: 500px) {
            font-size: 1rem;
            width: 100%;
          }
        }
        &__text {
          width: 50%;
          font-size: 2vw;
          @media (max-width: 1022px) {
            font-size: 1rem;
            width: 100%;
          }
          @media (max-width: 800px) {
            font-size: 0.8rem;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
