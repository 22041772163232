import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import header from './components/subcomponents/Header.vue'
import footer from './components/subcomponents/Footer.vue'
import { createHead, Head } from '@vueuse/head'

const app = createApp(App)
app.component('Head', Head)
app.component('header-item', header)
app.component('footer-item', footer)
app.use(createHead()).use(store).use(router).mount('body')
