import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import store from '../store'

const routes = [
  {
    path: '/kalender',
    name: 'Calendar',
    component: () => import(/* webpackChunkName: "lazy" */ '../components/Calendar.vue')
  },
  {
    path: '/kalender/:event',
    name: 'CalendarEvent',
    component: () => import(/* webpackChunkName: "lazy" */ '../components/CalendarEvent.vue')
  },
  {
    path: '/nyheter/:post?',
    name: 'News',
    component: () => import(/* webpackChunkName: "lazy" */ '../components/Posts.vue')
  },
  {
    path: '/sokresultat',
    name: 'Search',
    component: () => import(/* webpackChunkName: "lazy" */ '../components/Search.vue')
  },
  {
    path: '/sponsorer',
    name: 'Sponsors',
    component: () => import(/* webpackChunkName: "lazy" */ '../components/Page.vue')
  },
  {
    path: '/foreningen/hitta-hit',
    name: 'Map',
    component: () => import(/* webpackChunkName: "lazy" */ '../components/Map.vue')
  },
  {
    path: '/anlaggningen/hittahit',
    name: 'Map2',
    component: () => import(/* webpackChunkName: "lazy" */ '../components/Map.vue')
  },
  {
    path: '/:url1',
    name: 'Page',
    component: () => import(/* webpackChunkName: "lazy" */ '../components/Page.vue'),
    children: [
      {
        path: ':url2',
        component: () => import(/* webpackChunkName: "lazy" */ '../components/Page.vue'),
        children: [
          {
            path: ':url3',
            component: () => import(/* webpackChunkName: "lazy" */ '../components/Page.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/',
    name: 'Start',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.state.menu || store.dispatch('GET_FRAMEWORK')
  next()
})

export default router
