/* eslint-disable eqeqeq */
import { createStore } from 'vuex'

export default createStore({
  state: {
    menu: null,
    events: null
  },
  mutations: {
    GET_FRAMEWORK (state, { menu, events, footer }) {
      state.menu = menu
      state.events = events
      state.footer = footer
    }
  },
  actions: {
    GET_FRAMEWORK ({ commit }) {
      return new Promise((resolve, reject) => {
        fetch('/api/framework')
          .then(response => response.json())
          .then(data => {
            const menu = unflatten(data.menu)
            commit('GET_FRAMEWORK', { menu, events: data.events, footer: data.footer })
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  modules: {
  }
})
function unflatten (array, parent, tree) {
  tree = typeof tree !== 'undefined' ? tree : []
  parent = typeof parent !== 'undefined' ? parent : { id: '0' }
  var children = array.filter(function (child) {
    return child.parentid == parent.id
  })
  if (children.length) {
    if (parent.id == '0') {
      tree = children
    } else {
      parent.children = children
    }
    children.forEach(function (child) {
      unflatten(array, child)
    })
  }
  return tree
}
